import React, { useState, useEffect } from "react"
import "./searchSlider.scss";


interface SliderReference {
   goNext: () => void;
   goBack: () => void;
   goToPage: (value: number) => void;
   totalPages: number;
   paginator: number;
}


const SearchSlider = (props: SliderReference) => {

   const { goNext, goBack, totalPages, paginator, goToPage } = props
   const limitPages = 7;
   const todos = Array(limitPages >= totalPages ? totalPages : limitPages ).fill('*');
   const [slider, setSlider] = useState<number>(0);

   useEffect( () => {
      setSlider(paginator >= limitPages ? paginator + 1 - limitPages : 0);
   }, [paginator] )

   return (
      <div className='m-searchSlider-section'>
         <div className='m-searchSlider-body hide-on-mobile'>
            <div>
               <button 
                  className={ `${paginator <= 0 ? 'normal' : 'active'} gtmResultadosBusquedaAtras m-searchSlider-back ` } 
                  disabled={paginator <= 0} 
                  onClick={goBack}>
                     Atrás
               </button>
               <ul>
                  {todos.map((_, index) => (
                     <li 
                        className={paginator == index+slider ? 'active' : 'normal'} 
                        onClick={ ()=> paginator == index+slider ? null : goToPage(index+slider) } 
                        key={index}
                     >{index+1+slider}</li>
                  ))}
               </ul>
               <button 
                  className={ `${paginator + 1 >= totalPages ? 'normal' : 'active'} gtmResultadosBusquedaSiguiente m-searchSlider-next` } 
                  disabled={paginator + 1 >= totalPages} onClick={goNext}>
                     Siguiente
               </button>
            </div>
         </div>
         <div className='m-searchSlider-bodyResponsive show-on-mobile'>
            <button className='m-searchSlider-buttonResponsive gtmResultadosBusquedaAtras' disabled={paginator <= 0} onClick={goBack}>Atrás</button>
            <ul>
               <li className='active'>{paginator+1}</li>
               <li className='inactive'>de</li>
               <li className='inactive'>{totalPages}</li>
            </ul>
            <button 
               className='m-searchSlider-buttonResponsive gtmResultadosBusquedaSiguiente' 
               disabled={paginator + 1 >= totalPages} 
               onClick={goNext}>
                  Siguiente
            </button>
         </div> 
      </div>

   )
}


export default SearchSlider
