import React, { useState, useEffect } from 'react';
import './filterSearchMobile.scss'
import Button from '../../atoms/button/button';

interface IRecentSearch {
   order: 'ASC' | 'DESC',
   setOrder: (value:'ASC' | 'DESC' ) => void
}

const FilterSearchMobile = (props: IRecentSearch) => {

   const [isOpenModal, setOpenModal] = useState(false)
   
   const [currentOrganization, changeOrganization] = useState('Más reciente');

   const [iOrder, setIOrder] = useState<'ASC' | 'DESC'>(props.order);

   useEffect( () => {
      setIOrder(props.order)
   }, [isOpenModal])

   const applyChangeTemp = (value: string, order:'ASC' | 'DESC' ) => {
      changeOrganization(value);
      setIOrder(order);
   }

   const doFilter = () => {
      setOpenModal(false);
      currentOrganization === 'Más reciente' ? props.setOrder('ASC') : props.setOrder('DESC');
   }

   const Mobilefilter = () => {
   return (
      <div className={`m-recent-searchmo show-on-mobile ${ isOpenModal ? 'm-recent-searchmo-show' : ''}`}>

         <div className='m-recent-searchmo-show-header'>
            <div className='m-recent-searchmo-show-header-cont'>
               Filtrar por: <span><button className='m-recent-searchmo-show-header-cont-icon icon-close' onClick={() => {setOpenModal(false)}}/></span>
            </div>
         </div>
         <div className='m-recent-searchmo-show-options'>
            <span className='m-recent-searchmo-show-options-title'>Ordenar por:</span>
            <ul className='m-recent-searchmo-show-options-checkbox'>
               <li className='m-recent-searchmo-show-options-checkbox-first'>
                  <input type='radio' id='c1'  name='Choseninput' defaultChecked={ iOrder === 'ASC' } value={'Más reciente'} onClick={() => applyChangeTemp('Más reciente', 'ASC') }/>
                  <label htmlFor={'c1'} className='m-textbox'>Más reciente</label></li>
               <li className='m-recent-searchmo-show-options-checkbox-second'>
                  <input type='radio' id='c2' name='Choseninput' defaultChecked={ iOrder === 'DESC' } value={'Más antiguo'} onClick={() => applyChangeTemp('Más antiguo', 'DESC') }/>
                  <label htmlFor={'c2'} className='m-textbox'>Más antiguo</label></li>
            </ul>
         </div>

      <div className='m-recent-searchmo-show-buttons'>
      <Button
               type={"button"}
               classname={` m-button-cleaner primary`}
               text={'Limpiar'}
               onClickAction={() => { 
                  applyChangeTemp('Más reciente', "ASC");
                  doFilter();
               }}
               isDisabled={false}
            />
         <Button
               type={"button"}
               classname={` m-button-filter secondary`}
               text={'Filtrar'}
               onClickAction={doFilter}
               isDisabled={false}
            />
            
            
      </div>
      </div>
   )
   }

   return (
      <div>
         <button className={`m-recent-search-buttonmobile show-on-mobile  ${ isOpenModal ? 'm-recent-searchmo' : '' }`} onClick={() => {setOpenModal(true)}}><i className='icon-list'></i>FILTRAR</button>
         <Mobilefilter />
      </div>
   )
}
export default FilterSearchMobile;
