import React, { useState } from 'react';
import './recentSearchs.scss'

interface IRecentSearch {
   order: 'ASC' | 'DESC',
   setOrder: (value:'ASC' | 'DESC' ) => void
}

const RecentSearch = (props: IRecentSearch) => {

   const [currentOrganization, changeOrganization] = useState('Más reciente');
   const setContenByDate = (change_org: string) => {
      changeOrganization(change_org === currentOrganization ? currentOrganization : change_org)
   }

   const [currentData, changeCurrentData] = useState(0)
   const  setCurrentData = (idx: number) => {
      changeCurrentData(idx === currentData ? currentData: idx)
   }

   const [activeButton, ButtonPressed] = useState('off')
   const PressedButton = (state: string) => {
      ButtonPressed(state === 'on' ? 'on' : 'off')
   }


   return (
      <div className='m-recent-search'>
         <button className={`m-recent-search-button ${activeButton === 'on' ? 'm-recent-search-button-revealed': ''}`} 
         onClick={() => { PressedButton(activeButton === 'on' ? 'off' : 'on')}}>

            <i className={` m-recent-search-icon ${currentOrganization === 'Más reciente'  ? 'icon-sort-amount-up' : 'icon-sort-amount-down'}
            ${activeButton === 'on' ? 'm-recent-search-icon-revealed': ''} `}/>
            {currentOrganization}
         </button>

         <div className={ `m-recent-search-options ${activeButton === 'on' ? 'm-recent-search-options-revel': ''}`}>
         <ul>
            <li className={`m-recent-search-options-revel-recent ${props.order === 'ASC' ? 'm-current-chosen' : ''} gtmResultadosBusquedaMasReciente`} 
            onClick={() => { setContenByDate('Más reciente')
               setCurrentData(0)
               PressedButton('off')
               props.setOrder('ASC')
               }}>
                  Más reciente
                  <i className={`${currentData == 0 ? 'icon-check' : ''}`}></i>
            </li>

            <li className={`m-recent-search-options-revel-older  ${props.order === 'DESC' ? 'm-current-chosen' : ''} gtmResultadosBusquedaMasAntiguo`} 
               onClick={() =>{
               setContenByDate('Más antiguo')
               setCurrentData(1)
               PressedButton('off')
               props.setOrder('DESC')
               }}>
                  Más antiguo
                  <i className={`${currentData == 1 ? 'icon-check' : ''}`}></i>
            </li>
         </ul>
         </div>
      </div>
   )
}

export default RecentSearch;
