import React from "react"
import Layout from "../components/organisms/layout/layout"
import SEO from "../shared/seo"
import BodySearchResults from '../components/organisms/body-search-results/bodySearchResults'


const ResultsPage = () => {
   
   return (
      <Layout hideHeaderDesktop={true} hideHeaderMobile={true} isNotRoot={true}>
         <SEO title="Resultados " />
         <BodySearchResults/>
      </Layout>
   );
};

export default ResultsPage;

