import React from "react";
import './cardResult.scss';
import Button from "../../../components/atoms/button/button";
import defaultImage from "../../../assets/images/logos/comfama/logo_3x2.png";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import { findTarget, findRel, OptimizeImage } from "../../../shared/utils/stringUtilities";

const CardResult = (props: CardResultInterface) => {

	const {
		infoType,
      title,
      description,
      imageSrc,
      imageAlt,
      imageType,
      date,
      gtmMark,
      linkTo
   } = props;
   
   const { window } = useBrowserMode();

   const goToLink = (_link) => {
      window.open(_link, '_self');
   }

	return (
		<div className={`m-card-result ${gtmMark}`}>
         <a className={`m-card-result__link show-on-mobile ${gtmMark}`} 
            href={ linkTo } 
            target={ findTarget(linkTo) }
            rel={findRel(linkTo)} 
         />
			<div className='m-card-result__img'>
            <img
               loading="lazy"
               onClick={ ()=>{ goToLink(linkTo) } } 
					className={`${gtmMark}`} 
					src= { OptimizeImage(imageSrc, imageType, 300) || defaultImage } 
					alt= { imageAlt || 'Imagen resultado búsqueda' }
				/>
			</div>
			<div className={`m-card-result__cont ${gtmMark}`} >
            <div className={`m-card-result__cont-left ${gtmMark}`}>
               <span className={`m-card-result__type ${gtmMark}`}> 
                  { infoType } 
               </span>
               <div 
                  onClick={ ()=>{ goToLink(linkTo) } } 
                  className={`m-card-result__title ${gtmMark}`}  > 
                  { title } 
               </div>
               <div className={`m-card-result__info ${gtmMark} hide-on-mobile`} > 
                  { description }
               </div>
            </div>
            <div className={`m-card-result__cont-right ${gtmMark}`}>
               <span className={`m-card-result__date ${gtmMark}`}>
                  { date }
               </span>
               <Button 
                  type= { "link" } 
                  classname= {`primary hide-on-mobile`}
                  text= { "Conoce más" }
                  href= { linkTo } 
                  isDisabled= { false }
                  gtmMark= { gtmMark } 
                  target= { "_self" }
               />
            </div>
			</div>
         <div className={`m-card-result__icon icon-chevron-right1 ${gtmMark} show-on-mobile`}></div>
		</div>
	)
}

interface CardResultInterface {
	infoType: string,
	title: string,
	description: any,
	imageSrc: string,
	imageAlt: string,
   imageType: string,
	date?: string,
	gtmMark?: string,
	linkTo: string
}

export default CardResult
