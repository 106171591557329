import React, { useEffect, useState } from "react";
import "./bodySearchResults.scss";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import CardResult from "../../molecules/card-result/cardResult";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getFormatDate } from "../../../shared/utils/dateUtilities";
import useMetaSearch from "../../../shared/hooks/useMetaSearch";
import Searcher from "../../molecules/searcher/searcher";
import SearchSlider from "../../molecules/search-slider/searchSlider";
import RecentSearch from "../../molecules/recentSearchs/recentSearchs";
import FilterSearchMobile from "../../molecules/filter-content/filterSearchMobile";
import useBrowserMode from "../../../shared/hooks/useBrowserMode";
import useScrollDetect from "../../../shared/hooks/useScrollDetect";
import { normalizeTwoWords } from "../../../shared/utils/stringUtilities";

const BodySearchResults = () => {
   const {
      filterPage,
      paginator,
      totalPages,
      doSearch,
      goNext,
      goBack,
      goToPage,
      order,
      setOrder,
      filterSearch,
      setLimit
   } = useMetaSearch();

   const { isBrowser, window } = useBrowserMode();
   const [searchWord, setSearchWord] = useState("");
   const { isScrollActive, refElement } = useScrollDetect();

   useEffect(() => {
      searchByUrlParams();
      if(isBrowser && window.screen.width <= 960) setLimit(4) 
   }, []);

   const searchByUrlParams = () => {
      let url = new URL(window.location.href);
      let jsonUrlParams = getJsonFromUrl(url.search);
      if (jsonUrlParams) {
         setSearchWord(jsonUrlParams.search);
         setOrder(jsonUrlParams.sort);
      }
      doSearch(jsonUrlParams.search);
      window.ga_debug = {trace: true};
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({'event': 'ClicBusca', 'category':'Busqueda', 'action': 'Clic', label: jsonUrlParams.search}); 
   };

   const getJsonFromUrl = (url: string) => {
      let query = url.substr(1);
      let result: { search: string; sort: "ASC" | "DESC" } = {
         search: "",
         sort: "ASC",
      };
      query.split("&").forEach(function (part) {
         let item = part.split("=");
         result[item[0]] =
            decodeURIComponent(item[1]) === "null"
               ? null
               : decodeURIComponent(item[1]);
      });
      return result;
   };

   return (
      <div className="o-search-res">
         <Breadcrumb content={"Inicio / Resultados de búsqueda"} />

         <FilterSearchMobile order={order} setOrder={setOrder} />

         <div
            className={`o-search-res__header ${
               isScrollActive ? "o-search-res__header--fixed" : ""
            }`}
            ref={refElement}
         >
            <Searcher
               type="comfama"
               compact={true}
               defaultPlaceholder={searchWord}
            />
            <div className="o-search-res__header-buttons">
               <RecentSearch order={order} setOrder={setOrder} />
            </div>
         </div>

         <div className="o-search-res__cont">
            <div className={`o-search-res__cont-head`}>
               {filterSearch ? (
                  <>
                     <h2 className="o-search-res__cont-title title-secondary">
                        {filterSearch.length > 0 ? (
                           <>Resultados para {`"${searchWord}"`} </>
                        ) : (
                           <>
                              {" "}
                              No encontramos resultados para {`"${searchWord}"`}{" "}
                           </>
                        )}
                     </h2>
                     <span className="o-search-res__cont-subtitle">
                        {filterSearch.length > 0 && (
                           <>
                              {" "}
                              Mostrando {filterSearch.length}{" "}
                              {filterSearch.length === 1
                                 ? "resultado"
                                 : "resultados"}{" "}
                           </>
                        )}
                     </span>
                  </>
               ) : (
                  <div className="o-search-res__cont-head--loading">
                     <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                     </div>
                  </div>
               )}
            </div>

            {filterPage.map((value, i) => (
               <CardResult
                  key={`item${i}`}
                  infoType={value.tipoContenido}
                  title={value.titulo}
                  description={
                     (value.descripcion && value.descripcion.json.content) &&
                     documentToReactComponents(value.descripcion.json)
                  }
                  imageSrc={value.image && value.image.file.url}
                  imageType={value.image && value.image.file.contentType}
                  imageAlt={value.image && value.image.title}
                  date={getFormatDate(value.fecha)}
                  gtmMark={`gtmResultadosOrganico${normalizeTwoWords(value.tipoContenido)}${normalizeTwoWords(value.titulo)}`}
                  linkTo={value.slug}
               />
            ))}
         </div>

         {filterPage.length > 0 && (
            <SearchSlider
               totalPages={totalPages}
               goToPage={goToPage}
               goNext={goNext}
               goBack={goBack}
               paginator={paginator}
            />
         )}
      </div>
   );
};

export default BodySearchResults;
